body {
    padding: 20px;
    background-color: #444;
    color: antiquewhite;
}

#gridContainer {
    padding-bottom: 10px;
}

table {
    background-color: gray;
    border-spacing: 0;
}

td {
    border: 1px solid rgb(90, 90, 90);
    width: 10px;
    height: 10px;
}

td.dead {
    background-color: transparent;
}

td.live {
    background-color: white;
}

input, button {
    margin-right: 0.5rem;
}